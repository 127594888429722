import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const Axios = axios.create({
    baseURL,
    responseType: 'json',
    timeout: 15000, // return timeout error ater 8 seconds
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json'
    }
});

/**
 * Authorization head should be fulfilled in interceptor.
 */
// Axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('jwtToken')}`;

/*
  The below is required if you want your API to return
  server message errors. Otherwise, you'll just get
  generic status errors.

  If you use the interceptor below, then make sure you
  return an err message from your express route:

*/
Axios.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

export default Axios;
