/**
 * Error 500 : Not found
 * @returns
 */
function Error500Page(props: any): JSX.Element {
  return (
    <>
      <main className="flex flex-col min-h-screen justify-center items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold">
            500
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tigh sm:text-5xl">
            Oups... il y a une erreur.
          </h1>
          <p className="mt-6 text-base leading-7">
            Une erreur est survenue suite à votre dernière action... Veuillez réessayer plus tard.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button 
              onClick={() => document.location.reload()}
              className="btn btn-primary w-[200px] justify-center">
              Retour
            </button>
          </div>
        </div>
        {props.env === "development" && (
        <code className="text-left my-5 px-6">
          <strong>error message: {props?.error?.message}</strong><br/>
          {JSON.stringify(props?.info?.componentStack)}
        </code>)}
      </main>
    </>
  );
}

export default Error500Page;
