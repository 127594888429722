import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from '../contexts/userProvider';
import { RegistrationProvider } from '../contexts/registrationProvider';
import { BaseRoutes } from './BaseRoutes';
import { Loader } from './layout/Loader/Loader';
import ErrorBoundary from '../hooks/ErrorBoundary';

function App() {
  return (
    /* Add high level `Suspense` in case if was not handled inside the React tree.  */
    <React.Suspense fallback={<Loader />}>
        <BrowserRouter>
          <ErrorBoundary env={process.env.NODE_ENV}>
              <UserProvider>
                <RegistrationProvider>
                  <BaseRoutes />
                </RegistrationProvider>
              </UserProvider>
          </ErrorBoundary>
        </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
