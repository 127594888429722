import React, { useRef, useEffect, useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext, ActionTypes } from "../../../contexts/userProvider";
import { GetMe } from "../../../utils/requests/auth";
import { Loader } from "../../layout/Loader/Loader";
// import { SaveLogSession } from "../../../utils/requests/logConnexion";
// import moment from "moment";

/**
 *
 * User connexion verification
 * Check on load if user access token exist
 * if token exist get user informations from API
 * update user store and activate guard
 * @param props
 * @returns
 */
function SecurtityGuard(props: { component?: React.ReactNode }) {
  const didRequest = useRef(false);
  const { userStore, dispatch } = useContext(UserContext);
  const { isAuthorized, userInfos } = userStore;
  const jwtToken: string|null = sessionStorage.getItem("jwtToken");
  const userRole: string|null = localStorage.getItem('userRole');
  const [showLoaderScreen, setLoaderScreen] = useState(true);
  const nav = useNavigate();

  // We should request user by authToken before rendering component
  useEffect(() => {
    const requestUser = async () => {
      try {
        GetMe()
          .then((res) => {
            const { data } = res;
            dispatch({
              type: ActionTypes.userLoaded,
              payload: { user: data },
            });
          })
          .catch(() => {
            dispatch({
              type: ActionTypes.logout,
            });
            setTimeout(() => {
                if(userRole === "Medecin") nav("/login-ps");
                nav("/login");
            }, 500);
          });
      } catch (error) {
        console.error(error);
      } finally {
        setLoaderScreen(false);
      }

      return () => {
        didRequest.current = true;
      };
    };
    if (userInfos === undefined) requestUser();
    // eslint-disable-next-line
  }, [isAuthorized, jwtToken, dispatch]);

  return showLoaderScreen ? (
    <Loader />
  ) : (
    <Guard jwtToken={jwtToken}>{props.component}</Guard>
  );
}

/**
 * GuardInterface
 */
interface GuardInterface {
  children: React.ReactNode;
  jwtToken: string | null;
}

/**
 * Router Guard
 * Check if path contain key words (admin, gerant, user)
 * redirect to content or redirect to 403 error page
 * @param props
 * @returns
 */
const Guard = ({ children, jwtToken }: GuardInterface) => {
  if (jwtToken === null) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default SecurtityGuard;
