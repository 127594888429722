import { ActionTypes, StoreInterface } from './registrationProvider';

interface ActionInterface {
    type: string;
    payload?: any;
}

export const RegistrationReducer = (state: StoreInterface, action: ActionInterface): any => {
    const data = action.payload?.userInfos;
    switch (action.type) {
        // User Registration ACTION
        case ActionTypes.userRegistration:
            return {
                step: 2,
                registrationInfos: {
                    user: data,
                    company: {
                        ...state.registrationInfos?.company
                    }
                }
            };
        // clinics Registration ACTION
        case ActionTypes.companyRegistration:
            let company = action.payload;
            const newSate = {
                step: 2,
                registrationInfos: {
                    company: {
                        name: company.name,
                        country: company.country,
                        activitySector: company.activitySector,
                        companyType: company.companyType,
                        staffNumber: company.staffNumber,
                        survey: company.survey,
                    },
                    user: {
                        ...state.registrationInfos?.user,
                    }
                }
            }
            try {
                return newSate
            } catch (error: any) {
                return {
                    ...newSate,
                    response: {
                        status: "error",
                        message: error.response?.data?.error || error.message
                    }
                };
            }
        // DEFAULT ACTION
        default:
            return state;
    }
};
