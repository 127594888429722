import React from "react";
import Error500Page from "../app/pages/Errors/Error500Page";

class ErrorBoundary extends React.Component<
{ children: any, env: string}, // PropsObject
{ hasError: Boolean, error: any, info: any,  } // StateObject
> {
    constructor(props: any) {
      super(props);
      this.state = { 
        hasError: false,
        error: null, 
        info: null, 
      };
    }

    static getDerivedStateFromError(error: any) {
        // On met à jour l’état afin que le prochain rendu affiche
        // l’UI de remplacement.
        return { hasError: true };
    }
  
    componentDidCatch(error: any, info: any) {
      this.setState((prev) => ({
        ...prev,
        error,
        info
      }))
    }
  
    render() {
      if (this.state.hasError) {
        // Vous pouvez afficher ici n’importe quelle UI de secours
        return <Error500Page env={this.props.env} error={this.state.error} info={this.state.info} />
      }
  
      return this.props.children;
    }
}

export default ErrorBoundary