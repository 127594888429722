import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ThemeConfig, { Theme } from '../src/app/ThemeConfig'
import './main.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // Learn more about ant theme config : https://ant.design/docs/react/customize-theme
  <ConfigProvider theme={{
    token: {
      colorPrimary: Theme?.primaryColor,
      colorPrimaryBg: Theme?.primaryColor,
      colorLink: Theme?.secondaryColor,
    },
    components: {
      App: {
        colorError: ThemeConfig?.Colors?.danger,
        colorPrimary: ThemeConfig?.Colors?.corailDark,
      },
      Button: {
        colorError: ThemeConfig?.Colors?.danger,
        colorPrimary: ThemeConfig?.Colors?.primary,
        colorPrimaryHover: ThemeConfig?.Colors?.corailDark,
      },
      Input: {
        colorPrimary: ThemeConfig?.Colors?.primaryLight,
        algorithm: true, // Enable algorithm
      }
    }
  }}>
    <App />
  </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
