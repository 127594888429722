import {Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './Loader.scss';

/**
 * Loader
 * @returns 
 */
const Loader = () => {
    return ( 
        <div data-testid="app-loader" className="loader-box">
            {/* <Spin /> */}
            <Spin indicator={<LoadingOutlined className="text-md text--primary" style={{ fontSize: 24 }} spin/>} />
        </div>
    );
}

/**
 * Circle Loader
 * @returns 
 */
const CircleLoader = <LoadingOutlined className="text-md text-white" spin />
const CircleBlackLoader = <LoadingOutlined className="text-md text-black" spin />
const CirclePrimaryLoader = <LoadingOutlined className="text-md text--primary" spin />

export {Loader, CircleLoader, CirclePrimaryLoader, CircleBlackLoader} ;