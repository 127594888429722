import React, { createContext, useReducer } from 'react';
import { RegistrationReducer } from './registrationReducer';

export interface RegistrationInterface {
    user: {
        lastname: string,
        firstname: string,
        email: string,
        password: string,
        recaptcha: string | null,
        termsConditions: boolean,
        newsletter: boolean
    },
    company: {
        name: string,
        country: string,
        activitySector: string,
        companyType: string,
        staffNumber: string,
        survey: []
    },
    response?: any
}

export interface StoreInterface {
    registrationInfos?: RegistrationInterface,
    step?: number
}

interface Props {
    children: React.ReactNode;
}

interface CtxValues {
    registrationStore: StoreInterface;
    dispatch: React.Dispatch<any>;
}

const initialState: StoreInterface = {
    registrationInfos: {
        user: {
            lastname: "",
            firstname: "",
            email: "",
            password: "",
            recaptcha: null,
            termsConditions: false,
            newsletter: false
        },
        company: {
            name: "",
            country: "",
            activitySector: "",
            companyType: "",
            staffNumber: "",
            survey: []
        }
    },
    step: 1
}

const RegistrationContext = createContext<CtxValues>({ registrationStore: initialState, dispatch: () => null })

const RegistrationProvider: any = ({ children }: Props) => {
    const [registrationStore, dispatch] = useReducer(RegistrationReducer, initialState);
    return (
        <RegistrationContext.Provider value={{ registrationStore, dispatch }}>
            {children}
        </RegistrationContext.Provider>
    )
}

const ActionTypes = {
    userRegistration: 'UserRegistration',
    companyRegistration: 'clinicsRegistration',
    nextStep: 'NextStep',
};

export { RegistrationContext, RegistrationProvider, ActionTypes }
